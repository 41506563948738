<template>
  <el-container>
    <el-aside>
      <el-aside width="200px">
      <HomeMenu></HomeMenu>
      </el-aside>
    </el-aside>
   <el-main>
      <div id="content" class="grid-content">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
  <Bottom></Bottom>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import Bottom from "@/components/Bottom";

export default {
  name: 'Home',
  components: {
    Bottom,
    HomeMenu
  }
}
</script>
