<template>
  <div id="bottom">
    <el-affix :offset="20" position="bottom">
      <el-row>
        <span style="color: #2c3e50 ">北京康倍斯&copy; 2021</span>
      </el-row>
    </el-affix>
  </div>
</template>

<script>
export default {
  name: "Bottom"
}
</script>

<style scoped>
#bottom {
  text-align: center;
}
</style>
