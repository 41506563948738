<template>

  <el-menu :collapse="isCollapse"
           class="el-menu-vertical-demo"
           default-active="1"
           :default-openeds="defaultopens"
           style="  text-align: center;"
           @close="handleClose"
           @open="handleOpen">
    <i class="el-icon-s-fold" @click="foldmenu"></i><i class="el-icon-s-unfold" @click="foldmenu"></i>
    <el-sub-menu index="1">
      <template #title>
        <i class="el-icon-menu"></i>
        <span>客户短信操作</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="1-1" @click="doopenSend">发送快递信息</el-menu-item>
        <el-menu-item index="1-2" @click="listExpress">查询快递信息</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="2">
      <template #title>
        <i class="el-icon-menu"></i>
        <span>快递公司管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="2-1" @click="addExpress">添加快递公司</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    <!--      <el-menu-item disabled index="3">-->
    <!--      <i class="el-icon-document"></i>-->
    <!--      <template #title>Navigator Three</template>-->
    <!--    </el-menu-item>-->
    <!--    <el-menu-item index="4">-->
    <!--      <i class="el-icon-setting"></i>-->
    <!--      <template #title>Navigator Four</template>-->
    <!--    </el-menu-item>-->
  </el-menu>


</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      defaultopens:[1,2]
    };
  },
  methods: {
    doopenSend() {
      this.$router.push({path: '/home/smsops'});
    },
    addExpress() {
      this.$router.push({path: '/home/addex'});
    },
    listExpress() {
      this.$router.push({path: '/home/exops'});
    },
    foldmenu() {
      if (this.isCollapse == true) {
        this.isCollapse = false
      } else {
        this.isCollapse = true
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

#content {
  padding: 20px;
}
</style>
